





























import Component from 'vue-class-component'
import InquiryPageLayout from '@/views/layouts/InquiryPageLayout.vue'
import { Action } from 'vuex-class'
import { INQUIRE_TALENT_CANCEL, INQUIRIES_FOR_COMPANY } from '@/store/inquiry/actions'
import { InquiryDto } from '@/store/inquiry/types'
import { GenericRequest, SimpleServerResponse } from '@/store/types'
import Enums from '@/common/Enums'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import { SortingInfo } from '@/store/commonFormInput/types'
import MetaComponent from '@/common/MetaComponent.vue'
@Component({
  components: { InquiryPageLayout }
})
export default class TalentInquiries extends MetaComponent {
  showErrorMessage = false
  isLoading = false
  errorMessage = ''
  private modalHeading = ''
  private modalExplanation = ''

  @Action(INQUIRIES_FOR_COMPANY)
  public getInquiries: (request: GenericRequest) => Promise<InquiryDto[]>

  @Action(INQUIRE_TALENT_CANCEL)
  inquireTalentCancel: (requestDto: GenericRequest) => Promise<SimpleServerResponse>

  private openInquiries: Array<InquiryDto> = [];
  private acceptedInquiries: Array<InquiryDto> = [];
  private declinedInquiries: Array<InquiryDto> = [];

  get canReadInquiries (): boolean {
    return hasRight(Rights.INQUIRY_READ_BY_COMPANY)
  }

  get sortable (): SortingInfo[] {
    return [
      { key: 'talentFirstName', label: this.$i18n.t('personal-information.firstName').toString() },
      { key: 'talentLastName', label: this.$i18n.t('personal-information.lastName').toString() },
      { key: 'inquiryModified', label: this.$i18n.t('inquiries.request-date').toString() },
      { key: 'jobAdTitle', label: this.$i18n.tc('job-ad.job-ad', 1).toString() }
    ]
  }

  openConfirmationModal (): void {
    this.modalHeading = this.$t('inquiries.cancel-request-confirmation').toString()
    this.modalExplanation = this.$t('inquiries.cancel-request-text').toString()
  }

  cancelRequest (selectedInquiry: InquiryDto): void {
    this.$root.$emit('load')
    const requestDto: GenericRequest = {
      query: [selectedInquiry.inquiryId as string],
      params: undefined
    }
    this.inquireTalentCancel(requestDto).then(() => {
      this.openInquiries.splice(this.openInquiries.findIndex(inquiry => inquiry.inquiryId === selectedInquiry.inquiryId), 1)
    }).catch(error => {
      this.errorMessage = error
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  showTalent (selectedInquiry: InquiryDto): void {
    this.$router.push({
      name: 'talent-profile-jobad',
      params: {
        id: selectedInquiry.talentId,
        jobAdId: selectedInquiry.jobAdId
      }
    })
  }

  created (): void {
    if (this.canReadInquiries) {
      this.isLoading = true
      Promise.all([
        this.getInquiries({ query: [Enums.OPEN_INQUIRY_TO_TALENT] }),
        this.getInquiries({ query: [Enums.INQUIRY_ACCEPTED_BY_TALENT] }),
        this.getInquiries({ query: [Enums.INQUIRY_DENIED_BY_TALENT] })
      ]).then((data) => {
        this.openInquiries = data[0]
        this.acceptedInquiries = data[1]
        this.declinedInquiries = data[2]
      }).finally(() => {
        this.isLoading = false
      })
    } else {
      if (!this.canReadInquiries) {
        this.showErrorMessage = true
        this.errorMessage = this.$t('warning.missing-rights').toString()
      } else {
        this.showErrorMessage = true
        this.errorMessage = this.$t('warning.general-error-message').toString()
      }
    }
  }
}
